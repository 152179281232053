<template>
  <v-card
    class="px-2 py-1 mx-2 my-2"
    style="background-color: white; border: 1px solid rgba(222,222,222,0.58);"
    flat
    @click="[updateTableListAndDataById(), gettingTable = true]"
  >
    <v-row>
      <v-col>
        <h3 class="mb-2">
          {{ info.title }}
        </h3>
      </v-col>
      <v-col
        class="text-right"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              dark
              icon
              :color="'blue-grey lighten-3'"
              :loading="gettingTable"
              v-on="on"
            >
              <v-icon large>
                mdi-table-arrow-down
              </v-icon>
            </v-btn>
          </template>
          <span>Загрузить данные таблицы</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    props: {
      info: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        gettingTable: false,
      }
    },
    methods: {
      updateTableListAndDataById () {
        this.$emit('updateTableListAndDataById', [this.info])
      },
    },
  }
</script>
